import React from 'react';
import { theme } from '@chakra-ui/core';

const customIcons = {
  logo: {
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <circle cx="111.5" cy="111.5" r="72.5" fill="#4FD1C5" />
          <g clipPath="url(#clip1)">
            <path
              d="M112.094 32.496c-1 .813-5.296 4.97-9.517 9.2-4.222 4.23-9.203 9.106-11.073 10.842-1.87 1.718-3.851 3.695-4.388 4.415-2.166 2.826-4.407 7.296-6.018 12.044-.425 1.219-.925 2.087-2.536 4.34-7.258 10.16-9.221 13.134-11.443 17.42-.685 1.311-1.703 3.066-2.277 3.86-2.518 3.602-3.055 5.653-4.944 18.86-1.092 7.611-1.24 8.294-3.24 14.87-2.222 7.241-2.907 10.455-3.444 16.071-.352 3.657-.26 9.975.204 14.5.518 4.951.148 9.532-1.037 13.115-1.056 3.141-3.296 6.817-5.722 9.403-.74.775-1.11 1.311-.962 1.348 1.166.406 3.536 2.586 7.998 7.37 2.222 2.383 5.74 4.581 9.166 5.764 4.98 1.717 14.164 3.565 18.886 3.805 1.018.055 2.814.148 3.98.203 4.24.222 4.833.388 11.387 3.233 6.148 2.66 9.814 3.306 17.553 3.066 13.294-.406 13.702-.443 20.497-1.958 5.555-1.238 8.536-1.644 12.128-1.663 2.481 0 5.888-.369 7.702-.831 3.648-.923 5.407-1.921 13.572-7.629 3.259-2.29 10.998-7.388 17.794-11.729l4.443-2.845.056-3.029c.111-4.859-.704-11.361-2-16.053-.962-3.491-1.518-5.763-1.851-7.407-.556-2.771-.648-3.436-1.481-9.864-.519-4.064-.686-5.154-1.111-7.296a59.072 59.072 0 0 1-.574-3.418c-.315-2.678-.723-9.365-.723-12.191 0-1.533-.055-3.436-.111-4.267l-.129-1.496-1.815-2.069c-2.647-3.03-2.851-3.344-5.869-9.624-1.648-3.38-3.463-6.225-7.018-10.917l-2.351-3.122h-1.315c-1.703.019-2.61.425-4.055 1.903-1.407 1.422-3.221 2.401-5.536 2.974-3.018.757-2.796.776-3.055-.222-.87-3.288-4.536-11.286-7.258-15.83-1.87-3.104-2.833-5.08-3.666-7.518-.926-2.66-1.315-4.415-2.352-10.585-1.61-9.587-2.518-12.173-5.332-15.165-1.333-1.423-1.907-1.848-5.166-3.713-3.074-1.755-3.37-1.958-5.518-4.046-2.018-1.94-4.055-3.38-6.684-4.691-1-.518-1.851-.924-1.888-.924-.037 0-.889.665-1.907 1.496z"
              fill="#000"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h222v222H0z" />
          </clipPath>
          <clipPath id="clip1">
            <path fill="#fff" transform="translate(0 31)" d="M0 0h237v191H0z" />
          </clipPath>
        </defs>
      </svg>
    ),
    viewBox: '0 0 222 222',
  },
  menu: {
    path: (
      <svg xmlns="http://www.w3.org/2000/svg">
        <rect width="19" height="19" fill="currentColor" />
        <rect y="38" width="19" height="19" fill="currentColor" />
        <rect y="76" width="19" height="19" fill="currentColor" />
      </svg>
    ),
    viewBox: '0 0 19 95',
  },
  grade: {
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="32.0318"
          y1="110.645"
          x2="45.0318"
          y2="38.6446"
          stroke="currentColor"
          strokeWidth="4"
        />
        <line
          x1="49"
          y1="111"
          x2="49"
          y2="26"
          stroke="currentColor"
          strokeWidth="4"
        />
        <line
          x1="72.0463"
          y1="110.428"
          x2="49.0463"
          y2="5.42795"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          d="M36 53L5.99999 109H43.2553H109L56.5 13"
          stroke="currentColor"
          strokeWidth="4"
        />
      </svg>
    ),
    viewBox: '0 0 116 116',
  },
  marker: {
    path: (
      <svg version="1.1" x="0px" y="0px" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            fill="currentColor"
            d="M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9
     C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8
     c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"
          />
        </g>
      </svg>
    ),
    viewBox: '0 0 365 560',
  },
};

const customTheme = {
  ...theme,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
};

export default customTheme;
